@font-face {
  font-family:'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: local('Ubuntu'), local('Ubuntu-Regular'),
  url(../../public/fonts/Ubuntu/Ubuntu-Regular.ttf) format('truetype')
}

@media only screen and (max-width : 991px) {
  .navbar-collapse {
    background: #0e1d34;
    border-radius: 15px;
    margin-top: 0.5rem;
  }
}

@media only screen and (max-width : 991px) {
  #navbar_username {
    display: none;
  }
}

.public_body {
  min-height: 100vh;
  background-size: cover;
  background-image: url("../../public/background.png");
  background-color: #0e1d34;
}

body {
  min-height: 100vh;
  background-size: cover;
  background-color: #0e1d34;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Ubuntu", sans-serif;
  color: #fff;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background-color: #2756ff;
  border-color: #2756ff;
}

h1 {
  font-family: "Ubuntu", sans-serif;
  padding-bottom: 1rem;
  color: #ffff;
}

p {
  color: #FFFF;
}

a {
  color: #0d6efd;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 1rem 0.5rem;
}

.app-unauthorized {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem 0.5rem;
}

.section-without-login {
  width: 100%;
  max-width: 420px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  background-color: rgba(0,0,0,0.4);
  margin-top: 40px;
}

.section-unauthorized {
  width: 100%;
  max-width: 1300px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  background-color: rgba(0,0,0,0.4);
  margin-top: 40px;
}

.section-authorized {
  width: 100%;
  max-width: 1300px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  background-color: rgba(0,0,0,0.4);
  margin-top: 30px;
}

form {
  display: inline-block;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
  padding-bottom: 1rem;
}

input[type="text"],
input[type="password"],
textarea {
  font-family: "Ubuntu", sans-serif;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: none;
  width: 90%;
  margin-bottom: 1rem;
}

label {
  margin-top: 1rem;
}

.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  color: #DC143C;
  padding: 0.25rem;
  bottom: 15px;
  position: relative;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-bottom: -0.4rem;
}

.successmsg{
  font-family: "Ubuntu", sans-serif;
  background-color: lightgreen;
  color: #000000;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.errmsg {
  font-family: "Ubuntu", sans-serif;
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.line {
  display: inline-block;
  font-size: 0.75rem;
}

.nav-bar {
  background-image: url("../../public/background.png");
  background-size: cover;
  height: 150px;
  width: 100%;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.nav-link, .nav-link:visited {
  color: #6c757d;
}

.nav-link:hover, .nav-link:active {
  color: #ffffff;
}

.table-modal > tbody > tr > td {
  width: 150px;
}

.table-striped {
  width: 100%;
}

.table-striped > tbody {
  cursor: pointer;
}

.table-striped > tbody:nth-child(odd) > tr > td {
  color: #DEDEDE;
  vertical-align: middle;
  text-align:center;
  background-color: #4B4B4B;
  border-bottom: none;
  --bs-table-accent-bg: #4B4B4B;
  line-height: 10px;
}

.table-striped > tbody > tr:nth-child(even) {
  border-bottom-width: 1px;
}

.table-striped > tbody:nth-child(even) > tr > td, .table-striped > thead > tr {
  color: #DEDEDE;
  vertical-align: middle;
  text-align:center;
  border-bottom: none;
  line-height: 10px;
}

.table-striped > tfoot > tr {
  color: #DEDEDE;
  text-align:center;
}

.table-striped-left-align {
  text-align: left !important;
}

.table-striped-column-l {
  width: 170px;
}

.tableDropdown, .tableDropdown > button, .tableDropdown > button:hover, .tableDropdown > button:visited {
  max-height: 38px;
  background-color: #0d6efd;
  float: right;
}

.plusTable > tbody > tr > td {
  width: 200px;
  height: 60px;
}

.plusTable > tbody > tr > td > p {
  padding: 0;
  margin: 0;
}

.dropdown-menu {
  min-width: 4rem;
}

.filter-section p {
  padding: 0;
  margin: 0;
}

.filter-section button {
  margin-top: 25px;
}

.filterInput-xs {
  max-width: 60px;
}

.filterInput-s {
  max-width: 90px;
}

.filterInput-m {
  max-width: 110px;
}

.filterInput-l {
  max-width: 130px;
}

.filterInput-xl {
  max-width: 150px;
}

.filterInput-xxl {
  max-width: 170px;
}

.list-group {
  position: absolute;
}

.form-switch label {
  color: #FFFFFF;
  display: inline;
}

.form-switch {
  padding-left: 20px;
}

.react-datepicker__header {
  min-height: 80px;
}

.react-datepicker__day-names {
  padding-top: 15px;
}

.react-datepicker-time__caption {
  margin-left: 40px;
}

.display-linebreak {
  white-space: pre-line;
}

.modal-header, .modal-body, .modal-footer {
  background: #DEDEDE;;
}

.modalButton {
  display: flex;
  flex-direction: column;
  justify-content: right;
}

.table-modal > tbody > tr > td > div > div > label {
  color: #000000;
}

.addButton {
  display: flex;
  float: right;
  cursor: pointer;
}
